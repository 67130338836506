/** @format */

import { colours, customFonts, fontSizes, layoutSizes, responsiveQuery } from "../../constants/css";
import styled, { css } from 'styled-components';
import { ArrowRight } from "../_global/Icons/ArrowIcon";
export var BannerWrapper = styled.div.withConfig({
  displayName: "style__BannerWrapper",
  componentId: "sc-18ifty4-0"
})(["position:relative;@media only screen and (max-width:", "){height:240px;", "}@media only screen and ", "{padding-top:1.5rem;}.slick-list{overflow:hidden;@media only screen and (min-width:", "){height:400px;}}"], layoutSizes.mobileWidth, function (props) {
  return props.paddingTop && "\n      padding-top: ".concat(props.paddingTop, "\n    ");
}, responsiveQuery.largerThanMobileNew, layoutSizes.desktopWidth);
var Banner = styled.section.withConfig({
  displayName: "style__Banner",
  componentId: "sc-18ifty4-1"
})(["background:url('", "') no-repeat;height:250px;width:300px;border-radius:4px;margin:0 5px;@media only screen and (min-width:", "){background:url('", "') no-repeat;height:400px;width:750px;margin:0 15px;}@media only screen and (max-width:", "){background-size:cover !important;height:210px;width:250px;}"], function (props) {
  return props.mobileImage;
}, layoutSizes.desktopWidth, function (props) {
  return props.desktopImage;
}, layoutSizes.mobileWidth);
var SliderPlaceholder = styled.div.withConfig({
  displayName: "style__SliderPlaceholder",
  componentId: "sc-18ifty4-2"
})(["height:250px;@media only screen and (min-width:", "){height:400px;}"], layoutSizes.desktopWidth);
var BannerText = styled.h1.withConfig({
  displayName: "style__BannerText",
  componentId: "sc-18ifty4-3"
})(["font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;color:#282b26;margin:2rem 0 0.5rem 0;position:relative;svg{position:absolute;margin-left:6px;}@media only screen and ", "{font-size:", ";}"], customFonts.ciutadellaMed, fontSizes.xl1, responsiveQuery.tabletAndDesktop, fontSizes.xxxl);
var Subtitle = styled.h2.withConfig({
  displayName: "style__Subtitle",
  componentId: "sc-18ifty4-4"
})(["font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;color:#282b26;margin-bottom:0.625rem;"], customFonts.ciutadellaMed, fontSizes.xxxl);
var TopListWrapper = styled.section.withConfig({
  displayName: "style__TopListWrapper",
  componentId: "sc-18ifty4-5"
})(["padding:2rem 0;"]);
var TopListBox = styled.div.withConfig({
  displayName: "style__TopListBox",
  componentId: "sc-18ifty4-6"
})(["padding:1rem 0 1rem 1rem;max-width:360px;margin:0 auto 1rem;@media only screen and (min-width:", "){margin:0;}"], layoutSizes.desktopWidth);
var TopListTitle = styled.h3.withConfig({
  displayName: "style__TopListTitle",
  componentId: "sc-18ifty4-7"
})(["font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:1;letter-spacing:normal;color:#282b26;margin-top:0;"], customFonts.ciutadellaMed, fontSizes.xxl);
var TopListItem = styled.div.withConfig({
  displayName: "style__TopListItem",
  componentId: "sc-18ifty4-8"
})(["display:inline-block;margin-bottom:0.5rem;width:100%;white-space:nowrap;"]);
var ListItemImgWrapper = styled.div.withConfig({
  displayName: "style__ListItemImgWrapper",
  componentId: "sc-18ifty4-9"
})(["width:60px;height:60px;border-radius:4px;margin-right:1rem;display:inline-block !important;border:solid 1px ", ";overflow:hidden;"], colours.qantasLightGrey);
var ListItemBlock = styled.div.withConfig({
  displayName: "style__ListItemBlock",
  componentId: "sc-18ifty4-10"
})(["display:inline-block;vertical-align:top;"]);
export var ShopLink = styled.p.withConfig({
  displayName: "style__ShopLink",
  componentId: "sc-18ifty4-11"
})(["font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;color:", ";text-decoration:none;margin:0;"], fontSizes.small, colours.qantasRed);
var ListItemHeading = styled.p.withConfig({
  displayName: "style__ListItemHeading",
  componentId: "sc-18ifty4-12"
})(["font-family:", ";font-size:", ";color:#000;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.44;letter-spacing:normal;margin-top:0;margin-bottom:0;"], customFonts.ciutadellaMed, fontSizes.large);
var ListItemSub = styled.p.withConfig({
  displayName: "style__ListItemSub",
  componentId: "sc-18ifty4-13"
})(["font-weight:normal;font-style:normal;font-stretch:normal;line-height:1;letter-spacing:normal;color:", ";font-size:", ";margin-top:0;margin-bottom:0;"], colours.qantasGrey, fontSizes.small);
var CategoryContainer = styled.div.withConfig({
  displayName: "style__CategoryContainer",
  componentId: "sc-18ifty4-14"
})(["min-width:150px;"]);
var CategoryImg = styled.div.withConfig({
  displayName: "style__CategoryImg",
  componentId: "sc-18ifty4-15"
})(["", " border-radius:50%;width:100px;height:100px;margin:auto;@media only screen and (min-width:", "){width:125px;height:125px;}"], function (props) {
  return props.imgsrc && "\n    background: url('".concat(props.imgsrc, "');\n  ");
}, layoutSizes.desktopWidth);
var CategoryTitle = styled.p.withConfig({
  displayName: "style__CategoryTitle",
  componentId: "sc-18ifty4-16"
})(["text-align:center;font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:1;letter-spacing:normal;color:", ";"], customFonts.ciutadellaMed, fontSizes.large, colours.qantasCharcoal);
export var HeroPrevArrowContainer = styled.div.withConfig({
  displayName: "style__HeroPrevArrowContainer",
  componentId: "sc-18ifty4-17"
})(["@media only screen and (min-width:", "){display:flex;justify-content:center;align-items:center;width:80px;height:400px;background-color:rgba(50,50,50,0.6);position:absolute;z-index:1;cursor:pointer;}"], layoutSizes.desktopWidth);
export var HeroNextArrowContainer = styled.div.withConfig({
  displayName: "style__HeroNextArrowContainer",
  componentId: "sc-18ifty4-18"
})(["@media only screen and (min-width:", "){&:before{content:'';width:80px;height:400px;background-color:rgba(50,50,50,0.6);position:absolute;top:0;right:0;cursor:pointer;}", "{position:absolute;top:193px;right:2rem;left:auto;}}"], layoutSizes.desktopWidth, ArrowRight);
export var StyledLink = styled.a.withConfig({
  displayName: "style__StyledLink",
  componentId: "sc-18ifty4-19"
})(["color:", ";cursor:pointer;text-decoration:underline;"], colours.qantasCharcoal);
export var HowItWorksMobile = styled.div.withConfig({
  displayName: "style__HowItWorksMobile",
  componentId: "sc-18ifty4-20"
})(["display:none;@media only screen and ", "{display:flex;justify-content:flex-start;margin-bottom:1rem;}"], responsiveQuery.mobileNew);
export var McoWrapper = styled.section.withConfig({
  displayName: "style__McoWrapper",
  componentId: "sc-18ifty4-21"
})(["scroll-margin-top:4rem;background-image:linear-gradient(to right,", ",", ");padding:1.5rem 0;"], colours.qantasWhite, colours.qantasGray91);
export var McoHeaderWrapper = styled.div.withConfig({
  displayName: "style__McoHeaderWrapper",
  componentId: "sc-18ifty4-22"
})(["display:flex;justify-content:space-between;a{margin-top:34px;color:", ";font-size:", ";position:relative;text-decoration:none;svg.arrow-red-right{position:relative;top:1px;margin:0 9px;}i.right-arrow{margin-left:2px;}@media only screen and ", "{font-size:", ";margin-top:2rem;text-decoration:underline;}}"], colours.qantasRed, fontSizes.base, responsiveQuery.largerThanMobileNew, fontSizes.small);
export var BonusBar = styled.div.withConfig({
  displayName: "style__BonusBar",
  componentId: "sc-18ifty4-23"
})(["background-color:", ";padding:0.8rem;text-align:center;border-bottom-right-radius:20px;border-top-left-radius:4px;margin-bottom:1rem;font-size:", ";span{background-color:", ";padding:0.3rem;border-bottom-right-radius:10px;border-top-left-radius:4px;line-height:2;@media only screen and (min-width:", "){line-height:1;}}"], colours.qantasWhite, fontSizes.large, colours.qantasAqua, layoutSizes.desktopWidth);
export var CategoryCarouselWrapper = styled.div.withConfig({
  displayName: "style__CategoryCarouselWrapper",
  componentId: "sc-18ifty4-24"
})(["padding-top:1rem;"]);
export var CategoryCarouselItem = styled.div.withConfig({
  displayName: "style__CategoryCarouselItem",
  componentId: "sc-18ifty4-25"
})(["min-width:90px;margin-right:12px !important;"]);
export var CategoryCarouselTitle = styled.p.withConfig({
  displayName: "style__CategoryCarouselTitle",
  componentId: "sc-18ifty4-26"
})(["font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;text-align:center;color:", ";margin:1rem 0 0 0;text-transform:uppercase;"], customFonts.ciutadellaReg, fontSizes.small, colours.qantasCharcoal);
export var CategoryCarouselImage = styled.div.withConfig({
  displayName: "style__CategoryCarouselImage",
  componentId: "sc-18ifty4-27"
})(["border-radius:50%;background-color:", ";height:4rem;width:4rem;margin:0 auto;display:flex;justify-content:center;align-items:center;"], colours.qantasMediumGrey);
var FavouritesOnBonusBanner = styled.div.withConfig({
  displayName: "style__FavouritesOnBonusBanner",
  componentId: "sc-18ifty4-28"
})(["@media only screen and ", "{display:none;}@media only screen and ", "{width:100%;font-size:", ";background-color:", ";padding:0 0.75rem 0 1rem;display:flex;justify-content:space-between;align-items:center;opacity:0;height:0;.bonusBanner-close{width:1.75rem;height:1.75rem;}", "}"], responsiveQuery.largerThanMobileNew, responsiveQuery.mobileNew, fontSizes.small, colours.qantasTeal, function (_ref) {
  var show = _ref.show;
  return show && css(["opacity:1;height:3.25rem;"]);
});
export { Banner, Subtitle, TopListWrapper, TopListBox, TopListTitle, CategoryImg, CategoryTitle, BannerText, CategoryContainer, TopListItem, ListItemImgWrapper, ListItemBlock, ListItemHeading, ListItemSub, FavouritesOnBonusBanner, SliderPlaceholder };